<script setup>
import Map from '@/components/map/the-map.vue'
import Table from '@/components/table/the-table.vue'
import { ref } from 'vue'
import TableCollapse from '@/components/table/components/table-collapse.vue'
import { useMainStore } from '@/store'
import OverlayAlert from '@/components/common/overlay-alert.vue'

let isShowTable = ref(true)
let animationCompleted = ref(false)
const mainStore = useMainStore()

const collapseTable = () => {
  isShowTable.value = !isShowTable.value
  animationCompleted.value = false
}

function onAfterLeave() {
  mainStore.toggleShowTable()
  animationCompleted.value = true
}

function onAfterEnter() {
  mainStore.toggleShowTable()
}

const collapseIconStyles = {
  left: '0',
  top: '16px'
}
</script>
<template>
  <div class="main-layout">
    <Transition
      name="collapse-table"
      @after-leave="onAfterLeave"
      @after-enter="onAfterEnter"
    >
      <div
        v-show="isShowTable"
        class="table"
      >
        <Table @collapse-table="collapseTable" />
      </div>
    </Transition>
    <Transition name="collapse-table">
      <table-collapse
        v-if="animationCompleted"
        :styles="collapseIconStyles"
        icon="angle-right"
        @collapse-table="collapseTable"
      />
    </Transition>
    <div class="map">
      <Map />
    </div>
  </div>
</template>
<style lang="scss">
.collapse-table-enter-active,
.collapse-table-leave-active {
  transition: opacity 0.3s linear;
}

.collapse-table-enter-from,
.collapse-table-leave-to {
  opacity: 0;
}

.collapse-table-enter-to,
.collapse-table-leave-from {
  opacity: 1;
}

.main-layout {
  flex: 1;
  display: flex;
  height: 100%;
  background: var(--card-bg);
  min-height: 0;
  max-height: calc(100% - 49px);
  position: relative;

  & .table {
    display: flex;
    border-right: 1px solid var(--bg);
    flex-direction: column;
    width: 60%;
    padding: 24px;
    position: relative;
  }

  & .alert {
    margin-top: 24px;
  }

  & .map {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
  }
}
</style>
