import * as Sentry from '@sentry/vue'

export default (app) => {
  if (import.meta.env.MODE === 'development') return

  Sentry.init({
    app,
    dsn: 'https://a19902fd574d226dac366cba37392101@o1240741.ingest.sentry.io/4506298960576512',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          'https://wgs-msk.soilbox.app/api/'
        ]
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
