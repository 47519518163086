<script setup>
import { onMounted, watch, inject } from 'vue'
import 'handsontable/dist/handsontable.full.min.css'
import { registerLanguageDictionary, ruRU } from 'handsontable/i18n'
import { useTableStore } from '@/store/table'
import { getDataFromLS, saveDataToLS } from '@/utils'
import { useTable } from '@/components/table/composables/table'
import { useDataTable } from '@/components/table/composables/data-table'
import { columnsConfig, decimalColumnsConfig } from '@/config/table'
import { exportTable } from '@/components/table/helpers'
import { SIcon, SText } from '@n966/soilbox_ui'
import { WGSMSKHeaders, MSKWGSHeaders } from '@/config/table'
import TableAlert from '@/components/table/components/table-alert.vue'
import TableToolbar from '@/components/table/components/table-toolbar.vue'
import TableCollapse from '@/components/table/components/table-collapse.vue'

registerLanguageDictionary(ruRU)

const $notify = inject('$notify')
const store = useTableStore()

const { table } = useTable()
const { addRow, checkCells } = useDataTable(table)

const emits = defineEmits(['collapse-table'])

onMounted(() => {
  const tableDataFromLS = getDataFromLS('pointsList')

  if (tableDataFromLS) {
    store.SET_STATE_FIELD('pointsList', tableDataFromLS)
  } else {
    store.SET_STATE_FIELD('pointsList', [])
  }
})

watch(
  () => store.pointsList,
  (newValue) => {
    if (newValue && table.value) {
      table.value.updateSettings({
        data: newValue,
        cells: checkCells
      })
    }
  }
)

const tableToolbarHandler = (id) => {
  switch (id) {
    case 1:
      table.value.undo()
      break
    case 2:
      table.value.redo()
      break
    case 3:
      copyDataToClipboard()
      break
    case 4:
      pasteText()
      break
    case 5: {
      exportTable(table.value)
    }
  }
}

const copyDataToClipboard = () => {
  const selectedRange = table.value.getSelectedRange()

  if (!selectedRange || selectedRange.length === 0) {
    console.error('No data to copy.')
    return
  }

  const { from, to } = selectedRange[0]

  let dataToCopy = ''

  for (let row = from.row; row <= to.row; row++) {
    let rowData = []
    for (let col = from.col; col <= to.col; col++) {
      rowData.push(table.value.getDataAtCell(row, col))
    }
    dataToCopy += rowData.join('\t')
    if (row < to.row) {
      dataToCopy += '\n'
    }
  }

  navigator?.clipboard
    ?.writeText(dataToCopy)
    .then(() => {
      $notify({
        message: 'Данные сохранены в буфер обмена',
        type: 'success'
      })
    })
    .catch((err) => {
      console.error('Ошибка при копировании данных: ', err)
    })
}

const pasteText = () => {
  navigator?.clipboard
    ?.readText()
    .then((clipText) => {
      const copyPastePlugin = table.value.getPlugin('copyPaste')

      if (!copyPastePlugin.copyableRanges.length) return
      copyPastePlugin.paste(clipText)
    })
    .catch(function (err) {
      console.error('Ошибка чтения из буфера обмена:', err)
    })
}
const selectHandle = (id) => {
  switch (id) {
    case 'WGS-MSK':
      {
        table.value?.updateSettings({
          columns: columnsConfig,
          nestedHeaders: WGSMSKHeaders,
          cells: checkCells
        })
      }
      break
    case 'MSK-WGS': {
      table.value?.updateSettings({
        columns: decimalColumnsConfig,
        nestedHeaders: MSKWGSHeaders,
        cells: checkCells
      })
    }
  }
}
const closeAlert = () => {
  table.value.updateSettings({
    height: 'calc(100% - 33px)',
    minRows: 26
  })
}

const clearTable = () => {
  store.pointsList = store.pointsList.map((point) => {
    point = {}
    return point
  })

  saveDataToLS('pointsList', store.pointsList)
}
</script>
<template>
  <table-toolbar
    @select-handle="selectHandle"
    @toolbar-action="tableToolbarHandler"
    @clear-table="clearTable"
  />
  <TableAlert @close-alert="closeAlert" />
  <TableCollapse @collapse-table="emits('collapse-table')" />
  <div class="table-wrapper">
    <div id="table" />
    <div
      class="table-wrapper__add-button"
      @click="addRow"
    >
      <s-icon
        name="fa-plus"
        color="var(--success)"
      />
      <s-text type="success">Добавить строку</s-text>
    </div>
  </div>
</template>
<style lang="scss">
.table-wrapper {
  margin-top: 12px;
  flex-grow: 1;

  &__add-button {
    border-radius: 0 !important;
    height: 32px !important;
    background: var(--bg);
    border: 1px solid var(--main-bg);
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 4.5px 16px;
    cursor: pointer;

    & svg {
      width: 16px;
      height: 16px;
    }
  }
}

@import 'table';
</style>
