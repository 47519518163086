import { defineStore } from 'pinia'

export const useTableStore = defineStore('table', {
  state: () => ({
    pointsList: null,
    isLoadingDataSheets: false,
    selectValue: null
  }),
  actions: {
    SET_STATE_FIELD(module, value) {
      this[module] = value
    }
  }
})
