import { defineStore } from 'pinia'

export const useMapStore = defineStore('map', {
  state: () => ({
    activeBaselayer: 'default',
    mapZones: null,
    isShowPolygons: true,
    hoveredPolygon: null
  }),
  actions: {
    SET_BASELAYER(value) {
      this.activeBaselayer = value
    },
    SET_STATE_FIELD(field, value) {
      this[field] = value
    }
  }
})
