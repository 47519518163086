<script setup>
import { watch, ref } from 'vue'
import { useMapStore } from '@/store/map'

const mapStore = useMapStore()
const tooltip = ref(null)
watch(
  () => mapStore.hoveredPolygon,
  (newValue) => {
    if (newValue) {
      tooltip.value = null
      tooltip.value = newValue
    } else {
      tooltip.value = null
    }
  }
)
</script>
<template>
  <Transition name="slide-fade">
    <div
      v-if="tooltip && tooltip.zone_name"
      :style="{
        top: `${tooltip.points.y + 8}px`,
        left: `${tooltip.points.x + 4}px`
      }"
      class="polygon-tooltip"
    >
      <div>
        {{ tooltip.zone_name }}
      </div>
      <div v-if="tooltip.zone_name_width">{{ tooltip.zone_name_width }}</div>
    </div>
  </Transition>
</template>
<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}

.polygon-tooltip {
  font-size: 12px;
  position: absolute;
  z-index: 999;
  top: 0;
  border-radius: 8px;
  border: 1px solid var(--main-bg);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  background: var(--card-bg);
  max-width: 160px;
  color: var(--text);
  text-align: center;
  z-index: 999;
  padding: 8px 12px;
}
</style>
