import bboxPolygon from '@turf/bbox-polygon'
import bbox from '@turf/bbox'
import extent from 'turf-extent'
import { getGeojson } from '@/utils'

const flyToFeatures = (mapgl, data) => {
  const { length } = data.features
  if (!length) return

  const geometry = bboxPolygon(bbox(data)).geometry
  const bounds = extent(geometry)

  mapgl.fitBounds(bounds, {
    maxZoom: length === 1 ? 18 : 12,
    padding: {
      top: 64,
      bottom: 64,
      left: 64,
      right: 64
    },
    maxDuration: 500
  })
}

export const centerMap = (mapgl, features) => {
  let data

  if (!features || !features.length) return
  const filteredList = features.filter(
    (p) =>
      p.lat !== null &&
      p.lat >= -90 &&
      p.lat <= 90 &&
      p.lon !== null &&
      p.lon >= -180 &&
      p.lon <= 180 &&
      p.id
  )

  data = getGeojson(
    filteredList.map((p) => ({
      ...p,
      x: p.lat || 0,
      y: p.lon || 0
    }))
  )

  flyToFeatures(mapgl, data)
}
