<script setup>
import { ref } from 'vue'
import { SAlert } from '@n966/soilbox_ui'

const emits = defineEmits(['close-alert'])

const alertTitle = `Заполните или скопируйте (Ctrl-C) из таблицы excel координаты
                    WGS-84 (Garmin) и автоматически получите значения координат в МСК согласно ГОСТ
                    32453-2017. Автоматическое определение зон происходит при пересчете из WGS в МСК. 
                    При Пересчете из МСК в WGS необходимо выбрать номер зоны вручную`

let isShowAlert = ref(true)

const closeAlert = () => {
  isShowAlert.value = false
  emits('close-alert')
}
</script>
<template>
  <s-alert
    v-if="isShowAlert"
    :title="alertTitle"
    icon="circle-info"
    class="alert"
    type="primary"
    text-small
    closable
    @close-alert="closeAlert"
  />
</template>

<style lang="scss">
.alert {
  & .s-icon {
    font-size: 22px;
  }
}
</style>
