import { watch } from 'vue'
import { getGeojson } from '@/utils'
import {
  highlightedColor,
  pointCircleColor,
  pointsConfig
} from '@/components/map/config'
import { useTableStore } from '@/store/table'
import { useMapStore } from '@/store/map'
import { useMainStore } from '@/store'

const pointsID = 's-points'

export const usePointsLayer = (mapgl) => {
  const mapStore = useMapStore()
  const tableStore = useTableStore()
  const mainStore = useMainStore()

  const pointsList = () => tableStore.pointsList || []
  const filteredPointsList = () =>
    pointsList().filter(
      (p) =>
        p.lat !== null &&
        p.lat >= -90 &&
        p.lat <= 90 &&
        p.lon !== null &&
        p.lon >= -180 &&
        p.lon <= 180 &&
        p.id
    )

  const addPoints = async () => {
    const data = getGeojson(
      filteredPointsList().map((p) => ({
        ...p,
        x: p.lat,
        y: p.lon
      }))
    )

    const source = mapgl.value.getSource(pointsID)
    if (source && mapgl.value) {
      source.setData(pointsID, {
        type: 'geojson',
        data
      })
    } else {
      mapgl.value.addSource(pointsID, {
        type: 'geojson',
        data
      })

      mapgl.value.addLayer({
        id: pointsID,
        source: pointsID,
        ...pointsConfig.points
      })

      mapgl.value.addLayer({
        id: `${pointsID}-label`,
        source: pointsID,
        ...pointsConfig.pointsLabel
      })
    }

    changePointHoverCursor()

    mapgl.value.on('click', pointsID, pointsClickHandler)
  }

  const pointsClickHandler = (e) => {
    const features = mapgl.value.queryRenderedFeatures(e.point)
    mainStore.SET_STATE_FIELD('activePoint', features[0].properties.id)
  }

  const changePointHoverCursor = () => {
    mapgl.value.on('mouseenter', pointsID, function () {
      mapgl.value.getCanvas().style.cursor = 'pointer'
    })
    mapgl.value.on('mouseleave', pointsID, function () {
      mapgl.value.getCanvas().style.cursor = ''
    })
  }

  const updatePoints = () => {
    if (!mapgl.value) return
    const data = getGeojson(
      filteredPointsList().map((p) => ({
        ...p,
        x: p.lat,
        y: p.lon
      }))
    )
    const source = mapgl.value.getSource(pointsID)

    if (source) {
      source.setData(data)
    }
  }

  watch(
    () => tableStore.pointsList,
    (_, oldValue) => {
      if (oldValue !== null) {
        updatePoints()
      }
    },
    {
      deep: true
    }
  )

  watch(
    () => mapStore.activeBaselayer,
    () => {
      mapgl.value.once('style.load', () => {
        setTimeout(() => {
          addPoints()
        }, 0)
      })
    }
  )

  watch(
    () => mainStore.highlightPointsID,
    (newValue) => {
      setColorForPoint(newValue)
    },
    { deep: true }
  )

  function setColorForPoint(array) {
    mapgl.value.setPaintProperty(pointsID, 'circle-color', [
      'case',
      ['in', ['get', 'id'], ['literal', array]],
      highlightedColor,
      pointCircleColor
    ])
  }

  return {
    addPoints,
    updatePoints
  }
}
