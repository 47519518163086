import { createWebHistory, createRouter } from 'vue-router'

import HomeView from '@/views/HomePage.vue'
import InfoPage from '@/views/InfoPage.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/info', component: InfoPage }
]

export const router = createRouter({
  history: createWebHistory(),
  routes
})
