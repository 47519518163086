<script setup>
import { computed } from 'vue'
import { useTableStore } from '@/store/table'
import { STitle, SText, SIcon, SAlert } from '@n966/soilbox_ui'

const WGSMSKConfig = [
  {
    fileTypes: 'kmz, kml',
    text: `Структура данных: XML, разметка Google Earth или OpenGIS.`,
    formatText: 'Формат координат: десятичные градусы.',
    alertText: `KMZ является zip-архивом KML файла. KML в свою очередь представляет из
        себя XML разметку пространственных данных сделанных по стандартам Google
        Earth или OpenGIS. В случае наличия в файле типов объектов отличных от
        объекта point, например: polygon, polyline и пр., вершины данных
        объектов будет преобразованы в объекты point. Процедура может занять
        существенное время.`,
    downloadLink: 'gis.kmz'
  },
  {
    fileTypes: 'asc',
    text: `Структура данных: стандартная Leica.`,
    formatText: 'Формат координат: градусы, минуты, секунды.',
    alertText: `Формат экспорта данных спутниковых измерений из программного комплекса Leica Geo Office.`,
    downloadLink: 'leica.asc'
  },
  {
    fileTypes: 'csv',
    text: `Структура данных: Номер / Имя точки, Широта, Долгота, Абс. отметка.`,
    formatText: 'Формат координат: градусы, минуты, секунды.',
    alertText: `Стандартный формат экспорта данных спутниковых измерений из программного комплекса Topcon Magnet Tools`,
    downloadLink: 'topcon.csv'
  },
  {
    fileTypes: 'txt',
    text: `Структура данных: №п.п., Широта, Долгота, Абс. отметка, Номер / Имя точки.`,
    formatText: 'Формат координат: десятичные градусы.',
    alertText: `Стандартный формат экспорта данных спутниковых измерений из программного комплекса Justin (JAVAD)`,
    downloadLink: 'justin.txt'
  }
]

const MSKWGSConfig = [
  {
    fileTypes: 'txt',
    text: `Структура данных: Номер / Имя точки, X, Y, Абс. отметка.`,
    formatText: 'Формат координат: метры.',
    downloadLink: 'msk.txt'
  }
]

const store = useTableStore()

const filterConfig = computed(() => {
  if (store.selectValue.id === 'WGS-MSK') {
    return WGSMSKConfig
  }

  return MSKWGSConfig
})
</script>

<template>
  <div class="table-import-info">
    <s-title type="small">
      Вы можете импортировать следующие типы данных:
    </s-title>
    <div class="table-import-info__content">
      <div
        v-for="item in filterConfig"
        :key="item.text"
        class="table-import-info__content-block"
      >
        <div class="table-import-info__content-title">
          <s-title
            color-type="info-color"
            type="small"
          >
            {{ item.fileTypes }}
          </s-title>
          <div
            v-if="item.alertText"
            v-tooltip.right="item.alertText"
          >
            <s-icon name="circle-info" />
          </div>
        </div>

        <div class="table-import-info__content-text">
          <s-text small>
            {{ item.text }}
          </s-text>
          <s-text small>
            {{ item.formatText }}
          </s-text>
          <a
            :href="`/files/${item.downloadLink}`"
            download
          >
            Скачать пример
          </a>
        </div>
      </div>
      <div
        class="table-import-info__alert"
        v-if="store.selectValue.id === 'MSK-WGS'"
      >
        <s-alert
          icon="circle-info"
          class="alert"
          type="primary"
          text-small
        >
          <s-text small>
            Для экспорта координат из Autocad в текстовый файл можно
            воспользоваться
            <a
              href="https://geodesist.ru/resources/geo-import-export.212/"
              target="_blank"
              >Решением</a
            >
            или его аналогами.
          </s-text>
        </s-alert>
      </div>
      <div class="table-import-info__footer">
        <s-text>
          В случае проблем с импортом файлов отправьте, пожалуйста, пример
          данных в телеграм с указанием ошибки
          <a
            href="https://t.me/nordmar"
            target="_blank"
            >https://t.me/nordmar</a
          >.
        </s-text>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.table-import-info {
  margin-top: 24px;
  overflow: auto;
  max-height: calc(100vh - 248px);

  &__alert {
    & .s-icon {
      font-size: 22px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    gap: 12px;

    & a {
      color: var(--primary);
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 6px;

      .s-icon {
        cursor: pointer;
        display: block;
        font-size: 14px;
        position: relative;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-left: 24px;

      a {
        font-size: 12px;
      }

      &:before {
        left: 4px;
        top: 7px;
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 8px;
        background-color: var(--info);
        border-radius: 2px;
      }
    }
  }

  &__footer {
    margin-top: 16px;
  }
}
</style>
