import { useMapStore } from '@/store/map'
import { utils, writeFile } from 'xlsx'

const {
  aoa_to_sheet,
  encode_cell,
  book_append_sheet,
  book_new,
  sheet_add_aoa
} = utils

export const getZone = (value, findBy) => {
  const mapStore = useMapStore()

  if (!mapStore.mapZones || !value) return

  return mapStore.mapZones.find((zone) => {
    if (!findBy) {
      if (zone.id === value) {
        return zone
      }
    }

    if (zone.fullZoneName === value) {
      return zone
    }
  })
}

export const exportTable = (table) => {
  const data = table.getData()
  const filteredData = data.filter((row) => {
    row.shift()
    if (row[0]) {
      return row
    }
  })

  const headers = table.getColHeader()
  const filteredHeaders = headers.filter((header) => header)

  const worksheet = aoa_to_sheet([])

  const nestedHeaders = [
    '',
    '',
    { label: 'WGS', colspan: 3 },
    { label: 'MSK', colspan: 2 },
    ''
  ]

  let currentCol = 0
  nestedHeaders.forEach((header) => {
    if (header && header.label) {
      const cellRef = encode_cell({ r: 0, c: currentCol })
      worksheet[cellRef] = { v: header.label }
      if (header.colspan && header.colspan > 1) {
        worksheet['!merges'] = worksheet['!merges'] || []
        worksheet['!merges'].push({
          s: { r: 0, c: currentCol },
          e: { r: 0, c: currentCol + header.colspan - 1 }
        })
      }
    }
    currentCol += header.colspan || 1
  })

  sheet_add_aoa(worksheet, [filteredHeaders], {
    origin: { r: 1, c: 0 }
  })
  sheet_add_aoa(worksheet, filteredData, { origin: { r: 2, c: 0 } })

  const workbook = book_new()
  book_append_sheet(workbook, worksheet, 'Sheet1')

  writeFile(workbook, `Таблица_Пересчет_координат_${toDay()}.xlsx`)
}

export const toDay = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1

  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

export function checkObjectFieldsAreEmpty(obj) {
  if (!obj) return

  return Object.entries(obj).every(([key, value]) => {
    return key === 'id' || !value
  })
}
