<script setup>
import { useMapStore } from '@/store/map'
import { SIcon } from '@n966/soilbox_ui'

const mapStore = useMapStore()

const clickHandler = () => {
  mapStore.SET_STATE_FIELD('isShowPolygons', !mapStore.isShowPolygons)
}
</script>
<template>
  <div class="map-polygons">
    <button
      v-tooltip="'Границы зон МСК'"
      :class="`map-polygons__button ${
        mapStore.isShowPolygons ? 'map-polygons__button--show' : ''
      }`"
      @click="clickHandler"
    >
      <s-icon
        name="draw-polygon"
        size="lg"
      />
    </button>
  </div>
</template>

<style lang="scss">
.map-polygons {
  position: absolute;
  top: 16px;
  right: 72px;
  background-color: var(--bg);
  border: 1px solid var(--main-bg);
  border-radius: 8px;
  z-index: 10;

  &__button {
    width: 40px;
    height: 40px;
    background-color: var(--bg);
    border-radius: 8px;
    outline: none;
    border: none;
    color: var(--text);
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 17.5px;
      height: 100%;
    }

    &--show {
      background-color: var(--primary--active);
    }
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 4px;
    }

    &:hover {
      background-color: var(--primary--select);
    }

    &--active {
      background-color: var(--primary--active);
    }
  }
}
</style>
