export const accessToken =
  'pk.eyJ1IjoiaWdvcnlhaXRza3kiLCJhIjoiY2o1ZHdld2cxMGZteTJxbnVzYTQ5YnRiYyJ9.RROlDzi1Vxh6yILdjarxCg'

export const mapStyles = {
  default: 'mapbox://styles/igoryaitsky/cly7h5giy00ge01qv74ue59zw',
  satelite: 'mapbox://styles/igoryaitsky/cly7h9hr900gg01qvezeucvb7',
  outdoors: 'mapbox://styles/igoryaitsky/cly7h89l500eu01pib1t9gv95'
}

export const baselayers = [
  { id: 'default', name: 'Схема', icon: '' },
  { id: 'outdoors', name: 'Рельеф', icon: '' },
  { id: 'satelite', name: 'Спутник', icon: '' }
]
