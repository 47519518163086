export const saveDataToLS = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const getDataFromLS = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

export const getGeojson = (array) => {
  return {
    type: 'FeatureCollection',
    features: array.map((item) => ({
      type: 'Feature',
      properties: {
        title: item.num || `Без имени (${item.id})`,
        id: item.id
      },
      geometry: {
        type: 'Point',
        coordinates: [item.y, item.x]
      }
    }))
  }
}

export function splitString(str) {
  if (!str) return

  return str.split(/(\d+)/).filter((x) => x !== '')
}
