import { createApp } from 'vue'
import centerPoint from './column-templates/center-point.vue'

export default {
  renderer: (table, td, row) => {
    td.style.backgroundColor = 'var(--secondary-bg)'
    td.style.position = 'relative'

    const app = createApp(centerPoint, {
      table,
      row
    })

    const mountNode = document.createElement('div')
    td.innerHTML = ''
    mountNode.classList.add('center-point')
    td.classList.add('htMiddle', 'htCenter')
    td.appendChild(mountNode)
    app.mount(mountNode)
  },
  readOnly: true,
  data: 'centerPoint',
  width: '20',
  columnSorting: {
    headerAction: false
  }
}
