export const pointCircleColor = '#3C98E7'
export const highlightedColor = '#55b77e'

export const pointsConfig = {
  points: {
    type: 'circle',
    paint: {
      'circle-color': pointCircleColor,
      'circle-radius': 6,
      'circle-stroke-width': 2,
      'circle-stroke-color': '#ffffff'
    }
  },
  pointsLabel: {
    type: 'symbol',
    layout: {
      'text-field': '{title}',
      'text-size': 14,
      'text-offset': [1, 0],
      'text-anchor': 'left',
      'text-font': ['Open Sans Bold']
    },
    paint: {
      'text-color': '#F8F7F7',
      'text-halo-width': 0.75,
      'text-halo-color': '#1e232b'
    }
  }
}

const structuredColors = [
  '01',
  '#ff0000',
  '02',
  '#ff1200',
  '03',
  '#ff2500',
  '04',
  '#ff3700',
  '05',
  '#ff4a00',
  '06',
  '#ff5d00',
  '07',
  '#ff6f00',
  '08',
  '#ff8200',
  '09',
  '#ff9500',
  '10',
  '#ffa700',
  '11',
  '#ffba00',
  '12',
  '#ffcd00',
  '13',
  '#ffdf00',
  '14',
  '#fff200',
  '15',
  '#f8ff00',
  '16',
  '#e6ff00',
  '18',
  '#d3ff00',
  '20',
  '#c0ff00',
  '21',
  '#aeff00',
  '22',
  '#9bff00',
  '23',
  '#88ff00',
  '24',
  '#76ff00',
  '25',
  '#63ff00',
  '26',
  '#50ff00',
  '27',
  '#3eff00',
  '28',
  '#2bff00',
  '29',
  '#18ff00',
  '30',
  '#06ff00',
  '31',
  '#00ff0c',
  '32',
  '#00ff1f',
  '33',
  '#00ff31',
  '34',
  '#00ff44',
  '35',
  '#00ff57',
  '36',
  '#00ff69',
  '37',
  '#00ff7c',
  '38',
  '#00ff8f',
  '39',
  '#00ffa1',
  '40',
  '#00ffb4',
  '41',
  '#00ffc7',
  '42',
  '#00ffd9',
  '43',
  '#00ffec',
  '44',
  '#00ffff',
  '45',
  '#00ecff',
  '46',
  '#00d9ff',
  '47',
  '#00c7ff',
  '48',
  '#00b4ff',
  '49',
  '#00a1ff',
  '50',
  '#008fff',
  '51',
  '#007cff',
  '52',
  '#0069ff',
  '53',
  '#0057ff',
  '54',
  '#0044ff',
  '55',
  '#0031ff',
  '56',
  '#001fff',
  '57',
  '#000cff',
  '58',
  '#0600ff',
  '59',
  '#1800ff',
  '60',
  '#2b00ff',
  '61',
  '#3e00ff',
  '62',
  '#5000ff',
  '63',
  '#6300ff',
  '64',
  '#7600ff',
  '65',
  '#8800ff',
  '66',
  '#9b00ff',
  '67',
  '#ae00ff',
  '68',
  '#c000ff',
  '69',
  '#d300ff',
  '70',
  '#e600ff',
  '71',
  '#f800ff',
  '72',
  '#ff00f2',
  '73',
  '#ff00df',
  '74',
  '#ff00cd',
  '75',
  '#ff00ba',
  '76',
  '#ff00a7',
  '77',
  '#ff0095',
  '78',
  '#ff0082',
  '79',
  '#ff006f',
  '83',
  '#ff005d',
  '86',
  '#ff004a',
  '87',
  '#ff0037',
  '89',
  '#ff0025',
  '82',
  '#ff0012'
]

export const polygonsConfig = {
  fill: {
    type: 'fill',
    paint: {
      'fill-opacity': 0.5,
      'fill-color': [
        'match',
        ['get', 'zone_color'],
        ...structuredColors,
        '#ccc'
      ]
    }
  },
  line: {
    type: 'line',
    paint: {
      'line-color': '#1e232b',
      'line-width': 0.5
    }
  },
  label: {
    type: 'symbol',
    layout: {
      'text-field': '{zone_name} \n{zone_name_width}',
      'text-size': 8,
      'text-font': ['Open Sans Regular']
    },
    paint: {
      'text-color': '#F8F7F7',
      'text-halo-width': 0.75,
      'text-halo-color': '#1e232b'
    }
  }
}
