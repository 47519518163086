<script setup>
import { SText, SButton, STitle } from '@n966/soilbox_ui'
import { useRouter } from 'vue-router'
import ButtonIcon from '@/components/ui/button-icon.vue'

const router = useRouter()
</script>

<template>
  <div class="info-page">
    <div class="info-page__content">
      <div class="info-page-header">
        <div
          class="info-page-header__back"
          @click="router.push('/')"
        >
          <s-button
            icon="chevron-left"
            mini
            simple
          />
          <s-text>Вернуться</s-text>
        </div>
        <s-title>Описание проекта</s-title>
      </div>

      <div class="info-page__body">
        <div class="info-page__image-wrapper">
          <img
            src="@/assets/images/info-image.png"
            alt=""
          />
        </div>
        <div class="info-page__text-wrapper">
          <div class="info-page__block">
            <s-title>Общие сведения</s-title>
            <div class="info-page__block-text">
              Сервис пересчета координат
              <span>wgs-msk.soilbox.app</span>
              предназначен для пересчета координат WGS в Местные Системы
              координат Российской Федерации. В качестве параметров пересчета
              используются открытые данные. Детальное описание используемой
              методики пересчета с оценкой точности приведено в
              <a
                target="_blank"
                href="https://geoinfo.ru/product/gladyshev-aleksandr-konstantinovich/magiya-transformacii-koordinat-ot-mestnogo-k-globalnomu-51340.shtml"
                class="info-page__block-text_bold"
              >
                статье.
              </a>
              Адаптировано под полноформатные экраны устройств (ПК, ноутбуки).
              Пересчет из МСК в WGS будет добавлен позднее.
            </div>
          </div>
          <div class="info-page__block">
            <s-title>Рекомендации по использованию</s-title>
            <ul class="info-page__block-list">
              <li>
                Используйте <span>Google Chrome</span> или
                <span>Яндекс Браузер.</span>
              </li>
              <li>
                Подберите под себя нужный масштаб страницы: Ctrl – "+" и Ctrl –
                "–". Для мышки: Ctrl – колесико мышки (за исключением области
                карты).
              </li>
              <li>
                Скопируйте массив данных из таблицы Excel, содержащий название
                (номер) точки, широту, долготу и абс. отметку (опционально) в
                буфер обмена (Ctrl-C или «Копировать» в Excel). Вставьте данные
                из буфера обмена в таблицу данных на сервисе (Ctrl-V или кнопка
                «Вставить»).
              </li>
              <li>
                Помимо копирования из буфера можно воспользоваться кнопкой
                импорта данных из <span>kmz, kml, asc, txt, csv.</span> Описание
                структуры данных в разных форматах смотрите в окне импорта
                данных.
              </li>
              <li>
                Если загруженные точки попадут в контуры полигонов МСК, то
                номера зон МСК определятся автоматически.
              </li>
              <li>
                Опционально номер зоны для каждой точки можно изменить в таблице
                вручную. Например, когда для одной области есть две разные МСК:
                МСК 26.1, МСК 26.2 и МСК от СК95. В окне ячейки конкретную зону
                МСК можно найти, введя часть ее названия.
              </li>
              <li>
                Выделите и скопируйте получившиеся результаты X, Y в таблицу
                Excel или воспользуйтесь инструментом экспорта.
              </li>
            </ul>
          </div>
          <div class="info-page__block">
            <s-title>Инструменты взаимодействия с картой</s-title>
            <ul class="info-page__block-list">
              <li>
                Используйте
                <button-icon icon-name="fa-solid fa-angle-left" />
                для перевода карты в полноэкранный режим
              </li>
              <li>
                Используйте
                <button-icon icon-name="fa-solid fa-expand" />
                для центрирования всех объектов в масштабе окна карты
              </li>
              <li>
                Используйте
                <button-icon icon-name="fa-solid fa-layer-group" />
                для переключения картографической подложки
              </li>
              <li>
                Используйте
                <button-icon icon-name="fa-solid fa-crosshairs" />
                для центрирования объекта из таблицы на карте
              </li>
              <li>Выделенные объекты в таблице подсветятся на карте</li>
            </ul>
          </div>
          <div class="info-page__block">
            <s-title>Обратная связь</s-title>
            <s-text>
              Формализация расчетов и экспертно-научное сопровождение проекта:
              Александр Гладышев,
              <a href="mailto:wgs-alex0308@yandex.ru">wgs-alex0308@yandex.ru</a>
            </s-text>
            <div class="info-page-feedback">
              <s-text class="info-page-feedback-title">
                По всем вопросам: Николай Никифоров,
                <a
                  target="_blank"
                  href="https://t.me/nordmar"
                  >https://t.me/nordmar,
                </a>
                <a href="mailto:nikiforov@soilbox.app">nikiforov@soilbox.app</a>
              </s-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-page {
  padding: 24px;
  display: flex;
  justify-content: center;
  max-height: calc(100% - 61px);
  overflow: hidden;
  position: relative;

  &__content {
    max-height: 100%;
    border: 1px solid var(--bg);
    background: var(--card-bg);
    max-width: 556px;
    padding: 1.5rem 1rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-header {
    display: flex;
    position: sticky;
    align-items: center;
    justify-content: center;
    top: 0;

    &__back {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      gap: 8px;
      top: 0;
      cursor: pointer;

      & button {
        width: 2rem !important;
        height: 2rem;
      }
    }
  }

  &__body {
    overflow: auto;
    max-height: calc(100% - 24px);
    padding-right: 8px;
  }

  &-feedback {
    &-title {
      margin-bottom: 4px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 12px;

    color: var(--text);
    font-size: 0.875rem;
    margin: 0;

    &-list {
      color: var(--text);
      font-size: 0.875rem;
      margin: 0;

      & li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-text {
      line-height: 1.5;

      &_bold {
        font-weight: 700;
      }
    }

    & span {
      font-weight: 700;
    }

    & a {
      text-decoration: none;
      color: var(--primary);
    }
  }

  &__image-wrapper {
    margin-bottom: 1rem;

    & img {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--main-bg);
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & svg {
      cursor: pointer;
    }
  }

  & a {
    text-decoration: none;
    margin-top: 12px;
  }

  &__link {
    margin-left: 8px;
  }
}
</style>
