<script setup>
import { computed, inject, ref } from 'vue'
import { SModal, SUpload } from '@n966/soilbox_ui'
import { useTableStore } from '@/store/table'
import useAxios from '@/composables/useAxios'
import { saveDataToLS } from '@/utils'
import { getZone } from '@/components/table/helpers'
import TableImportInfo from '@/components/table/components/table-import/table-import-info.vue'

const $notify = inject('$notify')
const tableStore = useTableStore()
const $axios = useAxios()

defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['toggle', 'close'])

const isLoadingFile = ref(false)

const importSuccessHandler = () => {
  emits('close')

  $notify({
    message: 'Импорт из файла произошел успешно',
    type: 'success'
  })

  isLoadingFile.value = false
}

const importErrorHandler = (e) => {
  console.log('error --- ', e)

  $notify({
    message: 'При импорте из файла произошла ошибка',
    type: 'error'
  })

  isLoadingFile.value = false
}

const uploadFile = async (file) => {
  isLoadingFile.value = true

  const formData = new FormData()
  formData.append('file', file)

  let actionURL = ''
  if (tableStore.selectValue.id === 'WGS-MSK') {
    actionURL = 'api/v1/converter/wgs2msk/file'
  } else {
    actionURL = 'api/v1/converter/msk2wgs/file'
  }

  const headers = {
    accept: 'application/json'
  }

  const response = await $axios.put(actionURL, formData, { headers })
  const data = response.data
  setNewArray(data)

  return response
}

const setNewArray = (array) => {
  const dataLength = array.length
  let currentIdx = 0

  const resultedArray = tableStore.pointsList.map((point, idx) => {
    let zone

    if (currentIdx === dataLength - 1) {
      point = {}
    }

    if (!point.id && currentIdx !== dataLength) {
      point = array[currentIdx]
      point.id = idx + 1
      zone = getZone(array[currentIdx].zone_out)

      if (zone) {
        point.zone_in = zone?.fullZoneName
      }

      currentIdx += 1
    }

    return point
  })

  tableStore.SET_STATE_FIELD('pointsList', resultedArray)
  saveDataToLS('pointsList', tableStore.pointsList)
}

const WGSMSKFilesTypes = '.kmz,.kml,.asc,.csv,.txt'
const MSKWGSFilesTypes = '.txt'

const acceptedFileTypes = computed(() => {
  if (tableStore.selectValue.id === 'WGS-MSK') {
    return WGSMSKFilesTypes
  }

  return MSKWGSFilesTypes
})
</script>

<template>
  <s-modal
    modal-height="auto"
    bodyClass="no-scroll"
    :marginY="0"
    title="Импорт из файла"
    :show="showModal"
    position-center
    @close="emits('toggle')"
  >
    <div class="table-import">
      <div
        class="table-import__upload"
        v-loading="isLoadingFile"
      >
        <s-upload
          :on-success="importSuccessHandler"
          :on-error="importErrorHandler"
          :request="uploadFile"
          :accepted-file-types="acceptedFileTypes"
        />
      </div>
      <table-import-info />
    </div>
  </s-modal>
</template>

<style lang="scss">
.s-modal {
  & .no-scroll {
    overflow: hidden;
  }
}

.table-import {
  &__upload {
    .s-upload {
      padding: 3rem;
    }
  }
}
</style>
