<script setup>
import { useMainStore } from '@/store'
import { SIcon } from '@n966/soilbox_ui'

const props = defineProps({
  row: {
    type: Number
  },
  table: {
    type: Object
  }
})

const mainStore = useMainStore()
const centerPoint = () => {
  const rowID = props.table.getDataAtRowProp(props.row, 'id')
  mainStore.changeCenterPoint(rowID)
}
</script>
<template>
  <div
    class="center-point"
    @click="centerPoint"
  >
    <s-icon
      color="var(--placeholder)"
      name="fa-crosshairs"
    />
  </div>
</template>
<style lang="scss">
.center-point {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  & svg {
    font-size: 14px;
  }
}
</style>
