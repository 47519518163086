<script setup>
import { SModal, SButton } from '@n966/soilbox_ui'
import PythonCourse from './updates/python-course.vue'
import UpdatesList from './updates/updates-list.vue'

defineProps({
  showModal: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['close-modal'])
</script>

<template>
  <s-modal
    title="Последние обновления"
    :show="showModal"
    @close="emits('close-modal')"
  >
    <div class="updates-modal">
      <section class="updates-modal__item">
        <python-course />
      </section>

      <section class="updates-modal__item">
        <updates-list />
      </section>
    </div>

    <template #footer>
      <s-button
        stretch
        @click="emits('close-modal')"
      >
        Понятно
      </s-button>
    </template>
  </s-modal>
</template>

<style lang="scss">
.updates-modal {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  padding-right: 0.5rem;

  &__item {
    position: relative;

    &:not(:last-child) {
      &:after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: var(--bg);
        position: absolute;
        bottom: -4rem;
      }
    }
  }
}
</style>
