import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    isLoading: true,
    isShowTable: true,
    centerPoint: null,
    highlightPointsID: null,
    activePoint: null,
    isShowLastUpdatesModal: false
  }),
  actions: {
    SET_STATE_FIELD(field, value) {
      this[field] = value
    },
    toggleLoading(value) {
      this.isLoading = value
    },
    toggleShowTable() {
      this.isShowTable = !this.isShowTable
    },
    setHighlightPointsID(array) {
      this.highlightPointsID = array
    },
    changeCenterPoint(value) {
      this.centerPoint = value
    }
  }
})
