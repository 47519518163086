import customColumns from '@/components/table/custom-columns'

export const columnsConfig = [
  { ...customColumns, columnSorting: { headerAction: false } },
  {
    readOnly: true,
    data: 'id',
    columnSorting: { headerAction: false },
    width: 30
  },
  {
    data: 'num'
  },
  {
    type: 'numeric',
    data: 'lat',
    allowInvalid: false,
    columnSorting: { headerAction: false },
    width: 90
  },
  {
    type: 'numeric',
    data: 'lon',
    allowInvalid: false,
    columnSorting: { headerAction: false },
    width: 90
  },
  {
    type: 'numeric',
    data: 'h',
    columnSorting: { headerAction: false }
  },
  {
    type: 'numeric',
    readOnly: true,
    data: 'x',
    columnSorting: { headerAction: false },
    width: 90
  },
  {
    type: 'numeric',
    readOnly: true,
    data: 'y',
    columnSorting: { headerAction: false },
    width: 90
  },
  {
    type: 'dropdown',
    optionLabel: 'zone_name',
    width: 90,
    data: 'zone_in',
    columnSorting: { headerAction: false }
  }
]

export const decimalColumnsConfig = [
  { ...customColumns },
  {
    readOnly: true,
    data: 'id',
    columnSorting: { headerAction: false }
  },
  {
    data: 'num'
  },
  {
    type: 'numeric',
    data: 'x',
    columnSorting: { headerAction: false }
  },
  {
    type: 'numeric',
    data: 'y',
    columnSorting: { headerAction: false }
  },
  {
    type: 'numeric',
    data: 'h',
    columnSorting: { headerAction: false }
  },
  {
    type: 'numeric',
    data: 'lat',
    readOnly: true,
    allowInvalid: false,
    columnSorting: { headerAction: false }
  },
  {
    type: 'numeric',
    data: 'lon',
    readOnly: true,
    allowInvalid: false,
    columnSorting: { headerAction: false }
  },
  {
    type: 'dropdown',
    optionLabel: 'zone_name',
    width: 90,
    data: 'zone_in',
    columnSorting: { headerAction: false }
  }
]

export const tableConfig = {
  copyPaste: true,
  minRows: 23,
  invalidCellClassName: 'invalidValue',
  height: 'calc(100% - 33px)',
  outsideClickDeselects: false,
  licenseKey: 'non-commercial-and-evaluation',
  stretchH: 'last',
  width: '100%',
  rowHeights: '28px',
  language: 'ru-RU',
  className: 'htMiddle htCenter',
  columnSorting: true,
  manualColumnResize: true,
  manualRowResize: true
}

export const WGSMSKHeaders = [
  [
    '',
    '',
    '',
    { label: 'WGS', colspan: 2 },
    '',
    { label: 'MSK', colspan: 2 },
    ''
  ],
  [
    '',
    '№пп',
    'Номер',
    'Широта, град',
    'Долгота, град',
    'Абс, м',
    'X, м.',
    'Y, м.',
    'Зона'
  ]
]

export const MSKWGSHeaders = [
  [
    '',
    '',
    '',
    { label: 'MSK', colspan: 2 },
    '',
    { label: 'WGS', colspan: 2 },
    ''
  ],
  [
    '',
    '№пп',
    'Номер',
    'X, м.',
    'Y, м.',
    'Абс, м',
    'Широта, град',
    'Долгота, град',
    'Зона'
  ]
]
