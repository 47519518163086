import posthog from 'posthog-js'

export default {
  install(app) {
    if (process.env.NODE_ENV === 'development') return

    app.config.globalProperties.$posthog = posthog.init(
      'phc_dOBq09Nz0ieRlj3e7y1qws9aGQzEXRYVVW51m6dL8J0',
      {
        api_host: 'https://app.posthog.com'
      }
    )
  }
}
