<script setup>
import { SIcon } from '@n966/soilbox_ui'

const props = defineProps({
  icon: {
    type: String,
    default: 'angle-left'
  },
  styles: {
    type: Object,
    default: () => ({
      right: '-40px'
    })
  }
})
const emits = defineEmits(['collapse-table'])
</script>
<template>
  <div
    v-tooltip="'Скрыть / Показать панель'"
    :style="styles"
    class="table-collapse"
  >
    <div>
      <button @click="emits('collapse-table')">
        <s-icon
          :name="props.icon"
          size="lg"
        />
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.table-collapse {
  position: absolute;
  z-index: 1;
  top: 16px;

  button {
    width: 40px;
    height: 40px;
    background-color: var(--card-bg);
    border: 1px solid var(--bg);
    border-radius: 0 8px 8px 0;
    color: var(--text);
    cursor: pointer;
    padding: 0;
    right: -40px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      height: 20px;
    }
  }
}
</style>
